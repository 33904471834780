import { memo, useEffect, useReducer, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Text from 'components/Text';
import FieldBase from 'components/FieldBase';
import Input from 'components/Input';
import Button from 'components/Button';
import Alert from 'components/Alert';
import CheckBox from 'components/Checkbox';
import Modal from 'components/Modal';
import InvoiceCheckbox from 'components/InvoiceCheckbox';
import PulseLoader from 'react-spinners/PulseLoader';
import CouponButton from 'components/CouponButton';
import { useNavigate } from 'react-router-dom';
import { HiXCircle } from 'react-icons/hi';
import {
  getProgram,
  submitContracts,
  createPurchase,
} from 'services/apiPayment';
import { getUrlParams } from 'utils';

import { colors, fonts } from 'styles/theme';
import {
  CreditCardNumberInput,
  CreditCardDateInput,
  CreditCardCCVInput,
  isValidCreditCardNumber,
  isValidCreditCardDate,
  isValidCreditCardCCV,
} from 'components/CreditCardInputs';
import { HiChevronUp } from 'react-icons/hi';

const PaymentFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  @media (max-width: 768px) {
    margin-bottom: 330px;
  }
`;
const CalculateBlock = styled.div`
  width: 35%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ProgramAndPaymentBlock = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const GeneralCardLayout = styled.div`
  border-radius: 16px;
  background: var(--genral-white-gray-800-bg, #fff);
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.04),
    0px 1px 2px -1px rgba(0, 0, 0, 0.02);
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  @media (max-width: 375px) {
    padding: 40px 16px;
  }
`;
const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const CalculateContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
const ProgramDescriptionWrapper = styled.div`
  > * {
    line-height: 36px;
    font-size: 14px;
  }
`;
const CreditCardTypeContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 25px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const CreditCardRowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
`;
const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.SHADES_300};
`;
const InvoiceCheckboxWrapper = styled.div`
  display: flex;
  display-direction: row;
  gap: 16px;
`;
const TextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  background: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_300};
  border-radius: 8px;
  resize: vertical;
  ${fonts['Body/16px_Regular']}
  outline: none;
`;
const OriginalPrice = styled.div`
  color: ${colors.SHADES_500};
  span {
    text-decoration: line-through;
  }
`;

const Amount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
`;

const CalculateTotal = styled.div`
  display: flex;
  display-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 5px 0px;
`;

const PrivacyHintWrapper = styled.div`
  display: flex;
  display-direction: row;
`;

const PrivacyHint = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.35px;
  color: ${colors.SHADES_900};
  a {
    color: #1c64f2;
  }
`;

const CalculateContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
`;

const CalculateTitle = styled.div`
  ${fonts['Body/16px_Regular']};
  color: ${colors.SHADES_900};
  flex: 1 1 auto;
`;
const CalculatePrice = styled.div`
  flex: 0 0 auto;
  ${fonts['Body/16px_Regular']};
  color: ${colors.SHADES_900};
`;

const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  background: ${colors.SHADES_000};
  border-radius: 8px;
`;

const ErrorModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 104px;
  }
`;

const AlertHintWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
`;

const MobileCalculatePanel = styled.div`
  background-color: white;
  display: none;
  position: fixed;
  z-index: 90;
  bottom: 0;
  padding: 16px 40px 40px 40px;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.04),
    0px 1px 2px -1px rgba(0, 0, 0, 0.02);
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  @media (max-width: 375px) {
    padding: 16px 16px 40px 16px;
  }
`;

const ExpendIcon = styled.div`
  transition: transform 0.3s ease;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const ExpendIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PaymentForm = () => {
  const clientId = getUrlParams('clientId');
  const programId = getUrlParams('programId');
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [program, setProgram] = useState([]);
  const [coupon, setCoupon] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [checkAgreement, setCheckAgreement] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState(
    'twoPartElectronicInvoice'
  );

  const [errors, setErrors] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      address: false,
      phone: false,
      email: false,
      cardNumber: false,
      cardDueDate: false,
      cardCcv: false,
      cardholderName: false,
      threePartElectronicInvoice: false,
      uibNumber: false,
    }
  );

  const [formData, updateFormData] = useReducer(
    (prev, next) => {
      const key = Object.keys(next)[0];
      if (errors[key]) {
        setErrors({ [key]: false });
      }
      return { ...prev, ...next };
    },
    {
      address: '',
      phone: '',
      email: '',
      comment: '',
      cardholderName: '',
      threePartElectronicInvoice: '',
      uibNumber: '',
      cardNumber: '',
      cardDueDate: '',
      cardCcv: '',
    }
  );

  const price = useMemo(() => {
    if (coupon) {
      if (coupon.amountType === 'PERCENTAGE') {
        return Math.floor(program.amount * coupon.amount);
      }
      if (coupon.amountType === 'DIRECTLY') {
        return program.amount - coupon.amount;
      }
    }
    return program.amount;
  }, [coupon, program]);

  const displayCurrency = program.currency === 'TWD' ? 'NT$' : program.currency;

  const handleSetError = (errorMessage) => {
    setErrorMessage(errorMessage);
    setShowErrorModal(!!errorMessage);
  };

  const handleFormValidation = (key) => {
    let formErrors = {
      address: !formData.address,
      phone: false,
      email: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email),
      cardNumber: !isValidCreditCardNumber(formData.cardNumber),
      cardDueDate: !isValidCreditCardDate(formData.cardDueDate),
      cardCcv: !isValidCreditCardCCV(formData.cardCcv),
      cardholderName: !formData.cardholderName,
    };

    if (selectedInvoiceType === 'threePartElectronicInvoice') {
      formErrors = {
        ...formErrors,
        threePartElectronicInvoice: !formData.threePartElectronicInvoice,
        uibNumber: !/^\d{8}$/.test(formData.uibNumber),
      };
    }

    if (key) {
      setErrors({ [key]: formErrors[key] });
    } else {
      setErrors(formErrors);
      return Object.values(formErrors).some((error) => error);
    }
  };

  const handleInvoiceChange = (type) => {
    setSelectedInvoiceType(type);
    if (type === 'threePartElectronicInvoice') {
      updateFormData({ threePartElectronicInvoice: '', uibNumber: '' });
    }
  };

  const handleSubmit = async () => {
    const hasFormError = handleFormValidation();
    if (hasFormError) return;

    setIsLoading(true);

    const contractData = {
      clientId,
      programIds: [programId],
      comment: formData.comment,
      ...(coupon && { couponIds: [coupon.id] }),
      info: {
        cardholderName: formData.cardholderName,
        cardNumber: formData.cardNumber,
        cardDueDate: formData.cardDueDate,
        cardCcv: formData.cardCcv,
        clientEmail: formData.email,
        clientPhone: formData.phone,
        invoiceCompanyAddress: formData.address,
        ...(selectedInvoiceType === 'threePartElectronicInvoice' && {
          invoiceCompanyLetterhead: formData.threePartElectronicInvoice,
          invoiceUnifiedBusinessNo: formData.uibNumber,
        }),
      },
    };

    try {
      const { id, redirectUrl, orderId } = await submitContracts(contractData);
      await createPurchase({ clientId, contractIds: [id] });

      if (redirectUrl) {
        window.location.replace(redirectUrl);
      } else {
        navigate('/success', {
          state: {
            orderId,
            amount: price,
          },
        });
      }
    } catch (e) {
      const error = await e;
      handleSetError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!programId || !clientId) {
      setDisableSubmit(true);
      handleSetError('Missing programId or clientId，請聯繫 info@alleypin.com');
    } else {
      setDisableSubmit(false);
      handleSetError('');
      setIsLoading(true);

      getProgram(programId)
        .then((data) => {
          setProgram(data);
        })
        .catch(async (e) => {
          const error = await e;
          handleSetError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checkAgreement) {
      setDisableSubmit(true);
      return;
    }
    setDisableSubmit(false);
  }, [checkAgreement]);

  return (
    <>
      <PaymentFormWrapper>
        <ProgramAndPaymentBlock>
          {errorMessage && (
            <Alert
              type="error"
              title={
                <AlertHintWrapper>
                  <HiXCircle size={20} />
                  {errorMessage}
                </AlertHintWrapper>
              }
            />
          )}
          {/* program desc */}
          <GeneralCardLayout>
            <InfoText>
              <Text
                font="Body/19px_Heavy"
                color="SHADES_900"
                content={t('productName')}
              />
              <Text
                font="Body/16px_Medium"
                color="SHADES_900"
                content={program.name?.[currentLanguage]}
              />
              <Text
                font="Body/19px_Heavy"
                color="SHADES_900"
                content={t('program_content')}
              />
              <ProgramDescriptionWrapper>
                <Text
                  font="Body/16px_Regular"
                  color="SHADES_900"
                  preLine
                  content={program.description?.[currentLanguage]}
                />
              </ProgramDescriptionWrapper>
            </InfoText>
          </GeneralCardLayout>
          {/* credit card */}
          <GeneralCardLayout>
            <InfoText>
              <Text
                font="Body/19px_Heavy"
                color="SHADES_900"
                content={t('payment')}
              />
              <Input
                label={t('cardholderName')}
                value={formData.cardholderName}
                onChange={(e) =>
                  updateFormData({ cardholderName: e.target.value })
                }
                onBlur={() => handleFormValidation('cardholderName')}
                error={!!errors.cardholderName}
                helperText={errors.cardholderName ? t('cardholderError') : ''}
                placeholder={t('cardholderPlaceholder')}
                type="text"
                fullWidth
                required
              />
              <CreditCardRowWrapper>
                <CreditCardNumberInput
                  onChange={(value) => updateFormData({ cardNumber: value })}
                  onBlur={() => handleFormValidation('cardNumber')}
                  isError={errors.cardNumber}
                  width={450}
                />
                <CreditCardTypeContainer>
                  <div>
                    <img
                      width={58}
                      height={40}
                      src={'/images/visa.svg'}
                      alt="visa"
                    />
                  </div>
                  <div>
                    <img
                      width={58}
                      height={40}
                      src={'/images/mastercard.svg'}
                      alt="mastercard"
                    />
                  </div>
                  <div>
                    <img
                      width={58}
                      height={40}
                      src={'/images/jcb.svg'}
                      alt="jcb"
                    />
                  </div>
                </CreditCardTypeContainer>
              </CreditCardRowWrapper>
              <CreditCardRowWrapper>
                <CreditCardDateInput
                  value={formData.cardDueDate}
                  onChange={(value) => updateFormData({ cardDueDate: value })}
                  onBlur={() => handleFormValidation('cardDueDate')}
                  isError={errors.cardDueDate}
                />
                <CreditCardCCVInput
                  value={formData.cardCcv}
                  onChange={(value) => updateFormData({ cardCcv: value })}
                  onBlur={() => handleFormValidation('cardCcv')}
                  isError={errors.cardCcv}
                />
              </CreditCardRowWrapper>
            </InfoText>
          </GeneralCardLayout>
          {/* receipt */}
          <GeneralCardLayout>
            <InfoText>
              <Text
                font="Body/19px_Heavy"
                color="SHADES_900"
                content={t('receipt_content')}
              />
              <Input
                label={t('address')}
                value={formData.address}
                onChange={(e) => updateFormData({ address: e.target.value })}
                onBlur={() => handleFormValidation('address')}
                error={!!errors.address}
                helperText={errors.address ? t('addressError') : ''}
                placeholder={t('addressPlaceholder')}
                type="text"
                fullWidth
                required
              />
              <Input
                label={t('emailAddress')}
                value={formData.email}
                onChange={(e) => updateFormData({ email: e.target.value })}
                onBlur={() => handleFormValidation('email')}
                error={!!errors.email}
                helperText={errors.email ? t('emailAddressError') : ''}
                placeholder={t('emailAddressPlaceholder')}
                type="text"
                fullWidth
                required
              />
              <Input
                label={t('contactNumber')}
                value={formData.phone}
                onChange={(e) => updateFormData({ phone: e.target.value })}
                onBlur={() => handleFormValidation('phone')}
                error={!!errors.phone}
                helperText={errors.phone ? t('contactNumberError') : ''}
                placeholder={t('contactNumberPlaceholder')}
                type="text"
                maxLength={18}
                fullWidth
                required
              />
              <HorizontalLine />
              <FieldBase label={t('receipt_type')} required={true}>
                <InvoiceCheckboxWrapper>
                  <InvoiceCheckbox
                    marginBottom={0}
                    onChange={() =>
                      handleInvoiceChange('twoPartElectronicInvoice')
                    }
                    checked={selectedInvoiceType === 'twoPartElectronicInvoice'}
                  >
                    <Text
                      font="Body/14px_Medium"
                      color="SHADES_900"
                      content={t('twoPartElectronicInvoice')}
                    />
                  </InvoiceCheckbox>
                  <InvoiceCheckbox
                    marginBottom={0}
                    onChange={() =>
                      handleInvoiceChange('threePartElectronicInvoice')
                    }
                    checked={
                      selectedInvoiceType === 'threePartElectronicInvoice'
                    }
                  >
                    <Text
                      font="Body/14px_Medium"
                      color="SHADES_900"
                      content={t('threePartElectronicInvoice')}
                    />
                  </InvoiceCheckbox>
                </InvoiceCheckboxWrapper>
              </FieldBase>
              {selectedInvoiceType === 'threePartElectronicInvoice' && (
                <>
                  <Input
                    label={t('buyerName')}
                    value={formData.threePartElectronicInvoice}
                    onChange={(e) =>
                      updateFormData({
                        threePartElectronicInvoice: e.target.value,
                      })
                    }
                    checked={
                      selectedInvoiceType === 'threePartElectronicInvoice'
                    }
                    onBlur={() =>
                      handleFormValidation('threePartElectronicInvoice')
                    }
                    error={!!errors.threePartElectronicInvoice}
                    helperText={
                      errors.threePartElectronicInvoice
                        ? t('buyerNameError')
                        : ''
                    }
                    placeholder={t('buyerNamePlaceholder')}
                    type="text"
                    fullWidth
                    required
                  />
                  <Input
                    label={t('uibNumber')}
                    value={formData.uibNumber}
                    onChange={(e) =>
                      updateFormData({ uibNumber: e.target.value })
                    }
                    onBlur={() => handleFormValidation('uibNumber')}
                    error={!!errors.uibNumber}
                    helperText={errors.uibNumber ? t('uibNumberError') : ''}
                    placeholder={t('uibNumberPlaceholder')}
                    type="text"
                    fullWidth
                    required
                  />
                </>
              )}
              <Text
                font="Body/14px_Regular"
                color="SHADES_600"
                content={t('receipt_hint')}
              />
            </InfoText>
          </GeneralCardLayout>
          {/* coupon */}
          <GeneralCardLayout>
            <InfoText>
              <Text
                font="Body/19px_Heavy"
                color="SHADES_900"
                content={t('coupon')}
              />
              <CouponButton setCoupon={setCoupon} />
            </InfoText>
          </GeneralCardLayout>
          {/* comment */}
          <GeneralCardLayout>
            <InfoText>
              <Text font="Heading/H6/Medium" content={t('remark')} />
              <TextArea
                placeholder={t('remarkPlaceholder')}
                rows={4}
                value={formData.comment}
                onChange={(e) => updateFormData({ comment: e.target.value })}
              />
            </InfoText>
          </GeneralCardLayout>
        </ProgramAndPaymentBlock>
        <CalculateBlock>
          <GeneralCardLayout>
            <CalculateContentWrapper>
              <Text
                font="Body/19px_Heavy"
                color="SHADES_900"
                content={t('cart')}
              />
              <CalculateContent>
                <CalculateTitle>
                  {program.name?.[currentLanguage]}
                </CalculateTitle>
                <CalculatePrice>
                  {`${displayCurrency}${new Intl.NumberFormat('en-US').format(
                    program.amount
                  )}`}
                </CalculatePrice>
              </CalculateContent>
              {coupon && (
                <CalculateContent>
                  <Text
                    font="Body/14px_regular"
                    color="SHADES_900"
                    content={t('coupon_discount_title')}
                  />
                  <Text
                    font="Body/14px_regular"
                    color="SHADES_900"
                    content={`-${displayCurrency}${new Intl.NumberFormat(
                      'en-US'
                    ).format(program.amount - price)}`}
                  />
                </CalculateContent>
              )}
              <HorizontalLine />
              <Amount>
                {coupon && (
                  <OriginalPrice>
                    <span>
                      ${new Intl.NumberFormat('en-US').format(program.amount)}
                    </span>
                    {t('coupon_discount')}
                  </OriginalPrice>
                )}
                <CalculateTotal>
                  <Text
                    font="Body/16px_Heavy"
                    color="SHADES_900"
                    content={t('total')}
                  />
                  <Text
                    font="EN/Heading/H6/Heavy"
                    color="SHADES_900"
                    content={`${displayCurrency}${new Intl.NumberFormat(
                      'en-US'
                    ).format(price)}`}
                  />
                </CalculateTotal>
              </Amount>
              <PrivacyHintWrapper>
                <CheckBox onChange={setCheckAgreement}></CheckBox>
                <PrivacyHint>
                  {t('agreement')}{' '}
                  <a
                    href="https://alleypin.notion.site/Terms-Conditions-babdb75cd79e41d2a8f43a8ca33f0d93"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('serviceAgreement')}
                  </a>{' '}
                  {t('and')}{' '}
                  <a
                    href="https://www.alleypin.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('privacyPolicy')}
                  </a>
                </PrivacyHint>
              </PrivacyHintWrapper>
              <Button
                onClick={handleSubmit}
                disabled={disableSubmit || isLoading}
                fullWidth
                variant={'filled'}
                color={'primary'}
              >
                <Text
                  font="Body/16px_Medium"
                  color="SHADES_000"
                  content={t('payNow')}
                />
              </Button>
            </CalculateContentWrapper>
          </GeneralCardLayout>
        </CalculateBlock>
      </PaymentFormWrapper>
      {/* Loading */}
      <Modal open={isLoading} width={400} showCloseButton={false}>
        <LoadingContent>
          <PulseLoader color={colors.PRIMARY_400} margin={8} />
          <Text
            font="Heading/H5/Heavy"
            color="SHADES_900"
            content={t('loadingHint')}
          />
        </LoadingContent>
      </Modal>

      {/* Error Message */}
      <Modal
        open={showErrorModal}
        width={400}
        onClose={() => setShowErrorModal(null)}
      >
        <ErrorModalWrapper>
          <img src={'/images/error.svg'} alt="error" />
          <Text
            font="Heading/H5/Heavy"
            content={t('errorTitle')}
            textAlign="center"
            marginTop={32}
            marginBottom={12}
          />
          <Text
            font="Heading/Medium/Medium"
            content={errorMessage}
            marginBottom={32}
            textAlign="center"
          />
          <Button onClick={() => setShowErrorModal(null)} color="secondary">
            <Text
              font="Body/19px_Medium"
              color="SHADES_500"
              content={t('close')}
            />
          </Button>
        </ErrorModalWrapper>
      </Modal>
      {createPortal(
        <MobileCalculatePanel>
          <ExpendIconWrapper>
            <ExpendIcon
              isExpanded={isExpanded}
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
            >
              <HiChevronUp size={30} />
            </ExpendIcon>
          </ExpendIconWrapper>
          {isExpanded && (
            <>
              <Text
                font="Body/19px_Heavy"
                color="SHADES_900"
                content={t('cart')}
              />
              <CalculateContent>
                <CalculateTitle>
                  {program.name?.[currentLanguage]}
                </CalculateTitle>
                <CalculatePrice>
                  {`${displayCurrency}${new Intl.NumberFormat('en-US').format(
                    program.amount
                  )}`}
                </CalculatePrice>
              </CalculateContent>
              {coupon && (
                <CalculateContent>
                  <Text
                    font="Body/14px_regular"
                    color="SHADES_900"
                    content={t('coupon_discount_title')}
                  />
                  <Text
                    font="Body/14px_regular"
                    color="SHADES_900"
                    content={`-${displayCurrency}${new Intl.NumberFormat(
                      'en-US'
                    ).format(program.amount - price)}`}
                  />
                </CalculateContent>
              )}
              <HorizontalLine />
            </>
          )}
          <Amount>
            {coupon && (
              <OriginalPrice>
                <span>
                  ${new Intl.NumberFormat('en-US').format(program.amount)}
                </span>
                {t('coupon_discount')}
              </OriginalPrice>
            )}
            <CalculateTotal>
              <Text
                font="Body/16px_Heavy"
                color="SHADES_900"
                content={t('total')}
              />
              <Text
                font="EN/Heading/H6/Heavy"
                color="SHADES_900"
                content={`${displayCurrency}${new Intl.NumberFormat(
                  'en-US'
                ).format(price)}`}
              />
            </CalculateTotal>
          </Amount>
          <PrivacyHintWrapper>
            <CheckBox onChange={setCheckAgreement} marginBottom={0}></CheckBox>
            <PrivacyHint>
              {t('agreement')}{' '}
              <a
                href="https://alleypin.notion.site/Terms-Conditions-babdb75cd79e41d2a8f43a8ca33f0d93"
                target="_blank"
                rel="noreferrer"
              >
                {t('serviceAgreement')}
              </a>{' '}
              {t('and')}{' '}
              <a
                href="https://www.alleypin.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                {t('privacyPolicy')}
              </a>
            </PrivacyHint>
          </PrivacyHintWrapper>
          <Button
            onClick={handleSubmit}
            disabled={disableSubmit || isLoading}
            fullWidth
            variant={'filled'}
            color={'primary'}
          >
            <Text
              font="Body/16px_Medium"
              color="SHADES_000"
              content={t('payNow')}
            />
          </Button>
        </MobileCalculatePanel>,
        document.body
      )}
    </>
  );
};
export default memo(PaymentForm);
